<template>
  <div class="main has-bottom">
    <van-nav-bar class="top-bar" title="实名认证" left-arrow @click-left="onClickLeft"/>
    <div class="main-pane">
      <div class="pane-header">
        <div class="tips-title">根据国家相关规定，游戏用户需进行实名认证，未成年玩家将会受到一下游戏健康限制：</div>
        <div class="tips-ol">1、某些游戏时间段将受到限制</div>
        <div class="tips-ol">2、游戏充值金额将受到限制</div>
      </div>
      <div v-if="userInfo.real_name && userInfo.id_card" class="idCardBox">
        <div style="margin-right:10px;">{{ userInfo.real_name }} {{ userInfo.id_card }}</div>
        <van-icon class="size" :name="require('@/assets/images/personal/label-auth.png')"/>
      </div>
      <van-form ref="form" class="normal-label-form" v-else>
        <van-cell-group>
          <van-field label="真实姓名" v-model="user.real_name" name="姓名" :rules="userFormRules.userName"
                     placeholder="请输入您的真实姓名"/>
          <van-field v-model="user.id_card" label="身份证号码" name="身份证号码" :rules="userFormRules.card" type="card"
                     placeholder="仅支持18位身份证号"/>
        </van-cell-group>
        <van-button type="primary" block @click="onSubmit">立即认证</van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import {Button, CellGroup, Field, NavBar, Form, Toast, Icon} from "vant";
import {reactive, ref} from "vue";
import {useStore} from "vuex";
import {changeIdcard} from "@/api/user";
import "@/style/common.css"
import {onClickLeft} from "@/utils/function"
import {useRouter} from "vue-router";

export default {
  name: "PersonalAuth",
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Form.name]: Form,
    [Icon.name]: Icon,
  },
  setup() {
    const store = useStore();
    const user = reactive({
      real_name: '',
      id_card: ''
    })
    const userInfo = store.state.userInfo
    const form = ref(null);
    //验证规则
    const userFormRules = {
      userName: [
        {required: true, message: "姓名格式错误"},
        {pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '姓名格式错误'}
      ],
      card: [
        {required: true, message: "证件号码格式错误"},
        {
          pattern:
              /^\d{6}(19|2\d)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)?$/,
          message: "证件号码格式错误"
        },
      ],
    };
    //提交验证
    const router = useRouter();
    const onSubmit = () => {
      changeIdcard(user).then((res) => {
        Toast(res.message);
        store.commit('updateUserInfo')
        router.replace('/personal/person')
      })
    };

    return {
      user,
      userInfo,
      userFormRules,
      form,
      onClickLeft,
      onSubmit,
    };
  },
}
</script>

<style scoped>
.main-pane {
  padding: 16px;
}

.pane-header {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  margin-bottom: 5px;
}

.tips-title {
  color: #1D2129;
}

.tips-ol {
  color: #86909C;
}

.normal-label-form .van-cell-group {
  margin-bottom: 20px;
}

.normal-label-form .van-field {
  display: block;
  padding: 0px;
  margin-top: 5px;
}

.normal-label-form .van-cell-group::after, .normal-label-form .van-field::after {
  display: none;
}

:deep(.van-field__label) {
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
  line-height: 40px;
}

:deep(.van-field__body) {
  height: 44px;
  border-radius: 4px;
  border: 1px solid #DCDCDC;
  padding: 0px 12px;
  font-size: 14px;
}

.idCardBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 73px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #1D2129;
  padding: 0px 20px;
}

.size {
  font-size: 33px;
}
</style>
